<template>
    <div style="height:95%;margin:1%">
        <!-- <div style="display:flex">
            <el-card style="width:33%;margin-right: 1%" class="box-card">
                监控总数：
            </el-card>
            <el-card style="width:33%;margin-right: 1%" class="box-card">
            </el-card>
            <el-card style="width:33%;margin-right: 1%" class="box-card">
            </el-card>
        </div> -->
        <el-card style="width:100%;height:95%;" class="box-card">
            <el-table
                ref="filterTable"
                :data="tableData"
                style="width: 100%">
                <el-table-column
                prop="name"
                label="名称"
                width="180">
                </el-table-column>
                <el-table-column
                prop="type"
                label="类型"
                width="180">
                </el-table-column>
                <el-table-column
                prop="state"
                label="状态"
                width="120">
                <template slot-scope="scope">
                    <el-tag
                    :type="scope.row.state === '故障' ? 'danger' : 'success'"
                    disable-transitions>{{scope.row.state}}</el-tag>
                </template>
                </el-table-column>
                <el-table-column
                prop="address"
                label="安放地址"
                width="230">
                </el-table-column>
                <el-table-column
                prop="area"
                label="所属地区"
                width="180"
                column-key="date"
                :filters="[{text: '昌吉柳家庄镇', value: '昌吉柳家庄镇'}, {text: '博州瓦镇', value: '博州瓦镇'}, {text: '伊犁XXX乡', value: '伊犁XXX乡'}, {text: '奎屯', value: '奎屯'}]"
                :filter-method="filterHandler"
                >
                </el-table-column>
                <el-table-column
                prop="tag"
                label="相机标签"
                width="180"
                :filters="[{ text: '农田监控', value: '农田监控' }, { text: '路口监控', value: '路口监控' }, { text: '流动监控', value: '流动监控' }]"
                :filter-method="filterTag"
                filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag
                    :type="scope.row.tag === '路口监控' ? 'warning' : scope.row.tag === '农田监控' ? 'primary' : 'success'"
                    disable-transitions>{{scope.row.tag}}</el-tag>
                </template>
                </el-table-column>
                <el-table-column
                prop="remark"
                label="备注"
                width="180">
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
    export default {
        name: "HelloWorld",
        props: {
            msg: String
        },
        data() {
            return {
                tableData: [{
                    area: '昌吉柳家庄镇',
                    name: '1号农田监控',
                    type: '海康球机',
                    address: '昌吉柳家庄镇1号农田',
                    tag: '农田监控',
                    remark: '1号农田西北角监控',
                    state:'正常'
                    }, {
                    area: '昌吉柳家庄镇',
                    name: '路口监控',
                    type: '枪机',
                    address: '昌吉柳家庄镇XX路由东向西50m',
                    tag: '路口监控',
                    remark: 'XXX路与XXX路路口由东向西',
                    state:'正常'
                    }, {
                    area: '伊犁XXX乡',
                    name: '流动可操纵监控',
                    type: '海康球机',
                    address: '暂无',
                    tag: '流动监控',
                    remark: '1号车流动监控',
                    state:'正常'
                    }, {
                    area: '博州瓦镇',
                    name: '路口实时监控',
                    type: '枪型机',
                    address: 'XXX路路口向南150m',
                    tag: '路口监控',
                    remark: 'XXX路与XXX路路口由东向西',
                    state:'正常'
                    }, {
                    area: '博州瓦镇',
                    name: '农田流动监控',
                    type: '枪型机',
                    address: '暂无',
                    tag: '流动监控',
                    remark: '3号车流动监控',
                    state:'正常'
                    }, {
                    area: '伊犁XXX乡',
                    name: '5号农田监控',
                    type: '枪型机',
                    address: '伊犁XXX乡5号农田西北角',
                    tag: '农田监控',
                    remark: '5号农田西北角监控',
                    state:'故障'
                    }, {
                    area: '伊犁XXX乡',
                    name: '7号农田监控',
                    type: '海康球型机',
                    address: '伊犁XXX乡7号农田西北角',
                    tag: '农田监控',
                    remark: '7号农田西北角监控',
                    state:'正常'
                },{
                    area: '伊犁XXX乡',
                    name: '15号农田监控',
                    type: '海康球型机',
                    address: '伊犁XXX乡15号农田西北角',
                    tag: '农田监控',
                    remark: '15号农田西北角监控',
                    state:'故障'
                }]    
            };
        },
        watch: {
        },
        mounted() {

        },
        created() {
        },
        methods: {
            resetDateFilter() {
                this.$refs.filterTable.clearFilter('date');
            },
            clearFilter() {
                this.$refs.filterTable.clearFilter();
            },
            formatter(row) {
                return row.address;
            },
            filterTag(value, row) {
                return row.tag === value;
            },
            filterHandler(value, row, column) {
                const property = column['property'];
                return row[property] === value;
            }
        }
    };
</script>
<style>
    .button-d {
        margin-left: 10px;
        margin-top: 5px;
        width: 37px;
        height: 37px;
    }

    .button-o {
        margin-left: 10px;
        margin-top: 5px;
        width: 40px;
        height: 40px;
    }

    .o-img {
        width: 20px;
        height: 20px
    }

    .d-img {
        width: 14px;
        height: 14px
    }
</style>